@import '_variable';

body {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    color: #2c3e50;
}

p {
    font-size: 16px;
    line-height: 26px;
}

h2 {
    font-weight: 400;
    margin-top: 50px;
    font-size: 26px;
}

b, strong {
    font-weight: 700;
}

.social-media-icon {
    width: 28px;
    height: auto;
    margin: 10px 5px;
}
